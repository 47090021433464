import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  FadeReveal,
  Image,
  MarkdownContent,
  InternalLink,
  BasicCarousel,
  multipleComponentIterator,
  ReviewV2,
  FishermanIcon,
  CustomerReviewModal,
  SlideReveal,
  BackgroundImageTeamMember,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.hibiscussalon.com/about/"}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Section className={"header-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <Image
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "content_section_image",
                      numToSelect: 1,
                    })}
                    background={true}
                    loadStrategy={"eager"}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"content-column"}>
                <div>
                  <FadeReveal
                    duration={1250}
                    triggerOnce={true}
                    delay={450}
                    cascade={true}
                  >
                    <Header
                      as={"h1"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_header",
                        defaultValue: "About",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_description",
                      })}
                    />
                    <div className={"ctas-container"}>
                      <Button
                        secondary={true}
                        to={"/services/"}
                        as={InternalLink}
                        event={{
                          category: "Booking",
                          action: "Schedule Appointment Intent",
                        }}
                      >
                        View Services
                      </Button>
                    </div>
                  </FadeReveal>
                </div>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"reviews-section"} textAlign={"center"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_header",
                defaultValue: "What Our Clients Say",
              })}
            />
            <MarkdownContent
              className={"reviews-section-description"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_description",
              })}
            />
            <BasicCarousel
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              className={"reviews-carousel faded-sides-carousel"}
              leftArrowIcon={"fancy-arrow-left"}
              rightArrowIcon={"fancy-arrow-right"}
              slide={true}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <ReviewV2.Image />,
                        propMap: { src: "image" },
                        children: [
                          {
                            component: (
                              <FishermanIcon iconName={"quote-left"} />
                            ),
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={fishermanBusiness.type}
              triggerButtonProps={{
                basic: true,
                labelPosition: "right",
                icon: <FishermanIcon iconName={"fancy-arrow-right"} />,
              }}
              buttonText={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "submit_review_button",
                defaultValue: "Submit Review",
              })}
            />
          </Section>
          <Section className={"team-section"} textAlign={"center"}>
            <Header
              as={"h2"}
              className={"about-staff-header"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_header",
                defaultValue: "Featured Team Members",
              })}
            />
            <MarkdownContent
              className={"about-staff-description"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_description",
              })}
            />
            <Card.Group className={"featured-team-cards"} itemsPerRow={3}>
              <SlideReveal
                cascade={true}
                triggerOnce={true}
                className={"ui card"}
                fade={true}
                direction={"up"}
                children={multipleComponentIterator({
                  iterator: allFishermanBusinessTeam.nodes,
                  limit: 3,
                  components: [
                    {
                      component: (
                        <BackgroundImageTeamMember
                          useModal={false}
                          as={InternalLink}
                          moreInfoIconName={"fancy-arrow-right"}
                        />
                      ),
                      propMap: {
                        key: "_id",
                        "teamMember.name": "name",
                        "teamMember.role": "role",
                        "teamMember.photo": "gatsbyImage",
                        "teamMember.description": "description",
                        "teamMember.email": "email",
                        "teamMember.phone": "phone",
                        "teamMember.locations": "locations",
                        "teamMember.hours": "hours",
                        to: createTeamMemberSlug,
                      },
                    },
                  ],
                })}
              />
            </Card.Group>
            <Button
              to={"/team/"}
              secondary={true}
              as={InternalLink}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "see_all_team_button",
                defaultValue: "See All Stylists",
              })}
            />
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
